<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row d-flex justify-content-center">
      <div class="col-9">
        <div class="card">
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-md-12" v-if="bannerimageName">
                <img :src="bannerimageName" style="width: 100%; height: auto;" />

                <div class="upload-btn-wrapper mt-2">
                  <button class="btn">change image</button>
                  <input @change="handleImage" type="file" name="myfile" id="myfile"
                    accept="image/png, image/gif, image/jpeg" />
                </div>
              </div>
              <div v-else>
                <div class="upload-btn-wrapper mt-2">
                  <button class="btn">change image</button>
                  <input @change="handleImage" type="file" name="myfile" id="myfile"
                    accept="image/png, image/gif, image/jpeg" />
                </div>
              </div>
              <div class="col-md-3" v-if="bannerimage1Name">
                <img :src="bannerimage1Name" style="width: 100%; height: 50%;" />
                <div class="upload-btn-wrapper mt-2">
                  <button class="btn">change image</button>
                  <input @change="UploadbannerLogo1" type="file" name="myfile" id="myfile"
                    accept="image/png, image/gif, image/jpeg" />
                </div>
              </div>
              <div v-else>
                <div class="upload-btn-wrapper mt-2">
                  <button class="btn">change image</button>
                  <input @change="UploadbannerLogo1" type="file" name="myfile" id="myfile"
                    accept="image/png, image/gif, image/jpeg" />
                </div>
              </div>
              <div class="col-md-3" v-if="bannerimage2Name">
                <img :src="bannerimage2Name" style="width: 100%; height: 50%;" />
                <div class="upload-btn-wrapper mt-2">
                  <button class="btn">change image</button>
                  <input @change="UploadbannerLogo2" type="file" name="myfile" id="myfile"
                    accept="image/png, image/gif, image/jpeg" />
                </div>
              </div>
              <div v-else>
                <div class="upload-btn-wrapper mt-5">
                  <button class="btn">Add new image</button>
                  <input @change="UploadbannerLogo2" type="file" name="myfile" id="myfile"
                    accept="image/png, image/gif, image/jpeg" />
                </div>
              </div>
              <div class="col-md-3" v-if="bannerimage3Name">
                <img :src="bannerimage3Name" style="width: 100%; height: 50%;" />
                <div class="upload-btn-wrapper mt-2">
                  <button class="btn">change image</button>
                  <input @change="UploadbannerLogo3" type="file" name="myfile" id="myfile"
                    accept="image/png, image/gif, image/jpeg" />
                </div>
              </div>
              <div v-else class="upload-btn-wrapper mt-5">
                <button class="btn">Add new image</button>
                <input @change="UploadbannerLogo3" type="file" name="myfile" id="myfile"
                  accept="image/png, image/gif, image/jpeg" />
              </div>
              <div class="col-md-3 mt-3" v-if="bannerimage4Name">
                <img :src="bannerimage4Name" style="width: 100%; height: 50%;" />
                <div class="upload-btn-wrapper mt-2">
                  <button class="btn">change image</button>
                  <input @change="UploadbannerLogo4" type="file" name="myfile" id="myfile"
                    accept="image/png, image/gif, image/jpeg" />
                </div>
              </div>
              <div v-else class="upload-btn-wrapper mt-5">
                <button class="btn">Add new image</button>
                <input @change="UploadbannerLogo4" type="file" name="myfile" id="myfile"
                  accept="image/png, image/gif, image/jpeg" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-3">
                <label for="subcategory" class="form-label">Super category
                </label>
                <select class="form-control" @change="getSubCategories">
                  <option v-for="item in superCategories" :key="item.id" :value="item.id" :selected="
                      item.id == formData.superCategoryId ? true : false
                    ">
                    {{ item.fullName }}
                  </option>
                </select>
              </div>

              <div class="mb-3">
                <label for="subcategory" class="form-label">Subcategory </label>
                <select v-model="formData.subCategoryId" class="form-control" aria-label="Default select">
                  <option :value="item.id" v-for="item in subCategories" :key="item.id"
                    :selected="item.id == formData.subCategoryId ? true : false">
                    {{ item.fullName }}
                  </option>
                </select>
              </div>

              <div class="mb-3">
                <label for="fullname" class="form-label">Package Full Name</label>
                <input type="text" v-model="formData.fullName" class="form-control" />
              </div>

              <div class="mb-3">
                <label for="desciption" class="form-label">Description</label>
                <textarea name="description" class="form-control" rows="4" v-model="formData.description"></textarea>
              </div>

              <div class="form-row mb-3">
                <div class="col">
                  <label for="IsDeliveryByPickup" class="form-label">Delivery By Pickup?
                  </label>

                  <div class="form-check">
                    <input v-model="formData.IsDeliveryByPickup" class="form-check-input" type="checkbox"
                      :checked="formData.IsDeliveryByPickup ? true : false" />
                    <label class="form-check-label"> Yes </label>
                  </div>
                </div>
                <div class="col">
                  <label for="IsDeliveryByPickup" class="form-label">Delivery by Customer Location


                  </label>

                  <div class="form-check">
                    <input v-model="formData.IsDeliveryByCustLocation" class="form-check-input" type="checkbox"
                      :checked="
                        formData.IsDeliveryByCustLocation ? true : false
                      " />
                    <label class="form-check-label"> Yes </label>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <label for="effectiveStartDate
  " class="form-label">effective Start Date
                </label>
                <input type="date" v-model="formData.effectiveStartDate" class="form-control" />
              </div>

              <div class="mb-3">
                <label for="effectiveEndDate
  " class="form-label">effective End Date
                </label>
                <input type="date" v-model="formData.effectiveEndDate" class="form-control" />
              </div>

              <div class="mb-3">
                <label for="effectiveEndDate
  " class="form-label">Expired Quantyity
                </label>
                <input type="number" v-model="formData.ExpirationQuantity" class="form-control" />
              </div>

              <div class="mb-3">
                <label for="ExpirationQuantityUnit" class="form-label">Expiration Quantity Unit
                </label>
                <select v-model="formData.ExpirationQuantityUnitId" class="form-control" aria-label="Default select">
                  <option :value="item.id" v-for="item in ExpirationQntyUnit" :key="item.id">
                    {{ item.fullName }}
                  </option>
                </select>
              </div>

              <div class="mb-3">
                <label for="cost
  " class="form-label">Cost
                </label>
                <input v-model="formData.Cost" type="text" placeholder="cost" class="form-control" />
              </div>

              <div class="mb-3">
                <label for="IsDeliveryByPickup" class="form-label">Is Active
                </label>

                <div class="form-check">
                  <input class="form-check-input" v-model="formData.IsActive" type="checkbox"
                    :checked="formData.IsActive ? true : false" />
                  <label class="form-check-label"> Yes </label>
                </div>
              </div>

              <div class="mb-3">
                <label for="stock
  " class="form-label">Total Available Stock
                </label>
                <input type="text" v-model="formData.AvailableStock" placeholder="Total Available Stock"
                  class="form-control" />
              </div>

              <button @click.prevent="updateFoodHub" class="btn btn-block btn-primary">
                <b-spinner v-if="loading" type="grow"></b-spinner>
                <span v-else>Update</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import moment from "moment";


/**
 * Advanced table component
 */
export default {
  page: {
    title: "Food Pack",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      loading: false,
      title: "",
      items: [
        {
          text: "Oonpay",
          href: "/"
        },

        {
          text: "Add Food Pack",
          active: true
        }
      ],
      url: null,
      bannerimageName: "",
      logo: null,
      subCategoryId: "",
      superCategoryId: "",
      ExpirationQntyUnit: [],
      superCategories: [],
      subCategories: [],
      bannerimage1url: null,
      bannerimage1Name: "",
      bannerimage2url: null,
      bannerimage2Name: "",
      bannerimage3url: null,
      bannerimage3Name: "",
      bannerimage4url: null,
      bannerimage4Name: "",
      formData: {
        fullName: "",
        description: "",
        subCategoryId: "",
        IsDeliveryByPickup: "",
        IsDeliveryByCustLocation: "",
        effectiveStartDate: "",
        effectiveEndDate: "",
        ExpirationQuantity: "",
        ExpirationQuantityUnitId: "",
        Cost: "",
        IsActive: "",
        AvailableStock: ""
      }
    };
  },

  async created() {
    await this.$store.dispatch("foodhub/getRequiredData").then((res) => {
      console.log(res)
      this.superCategories = res.data.item3;
      this.ExpirationQntyUnit = res.data.item4;
      this.editData = res.data.item1.find(
        (data) => data.id == this.$route.params.id
      );

      this.formData.effectiveEndDate = moment(
        this.editData.effectiveEndDate
      ).format("YYYY-MM-DD");
      this.formData.effectiveStartDate = moment(
        this.editData.effectiveStartDate
      ).format("YYYY-MM-DD");
      this.formData.ExpirationQuantityUnitId = this.editData.expirationQuantityUnitId;
      this.formData.subCategoryId = this.editData.subCategoryId;
      (this.formData.superCategoryId = this.editData.superCategoryId),
        (this.formData.fullName = this.editData.fullName);
      this.formData.description = this.editData.description;
      this.formData.Cost = this.editData.cost;
      this.formData.AvailableStock = this.editData.availableStock;
      this.formData.ExpirationQuantity = this.editData.expirationQuantity;
      this.formData.IsDeliveryByPickup = this.editData.isDeliveryByPickup;
      this.formData.IsDeliveryByCustLocation = this.editData.isDeliveryByCustLocation;
      this.formData.IsActive = this.editData.isActive;
      this.bannerimageName = this.editData.imageUrl;
    });

    await this.$store
      .dispatch("foodhub/getFoodHubSubCat", this.formData.superCategoryId)
      .then((res) => {
        this.subCategories = res.data;
      });

    await this.$store
      .dispatch("foodhub/getPackageImages", this.editData.guid)
      .then((res) => {
        console.log(res)
        this.bannerimage1Name = res.data[0];
        this.bannerimage2Name = res.data[1];
        this.bannerimage3Name = res.data[2];
        this.bannerimage4Name = res.data[4];
      });
  },

  methods: {
    handleImage(e) {
      this.url = e.target.files[0];
      this.bannerimageName = URL.createObjectURL(this.url);
    },

    UploadbannerLogo1(e) {
      this.bannerimage1url = e.target.files[0];
      this.bannerimage1Name = URL.createObjectURL(this.bannerimage1url);
    },

    UploadbannerLogo2(e) {
      this.bannerimage2url = e.target.files[0];
      this.bannerimage2Name = URL.createObjectURL(this.bannerimage2url);
    },

    UploadbannerLogo3(e) {
      this.bannerimage3url = e.target.files[0];
      this.bannerimage3Name = URL.createObjectURL(this.bannerimage3url);
    },

    UploadbannerLogo4(e) {
      this.bannerimage4url = e.target.files[0];
      this.bannerimage4Name = URL.createObjectURL(this.bannerimage4url);
    },

    getSubCategories(e) {
      this.$store
        .dispatch("foodhub/getFoodHubSubCat", e.target.value)
        .then((res) => {
          this.subCategories = res.data;
        });
    },

    updateFoodHub() {
      const { guid, timeStamp } = this.editData;

      const payload = new FormData();

      payload.append("fullName", this.formData.fullName);
      payload.append("description", this.formData.description);
      payload.append("subCategoryId", this.formData.subCategoryId);
      payload.append("IsDeliveryByPickup", this.formData.IsDeliveryByPickup);
      payload.append(
        "IsDeliveryByCustLocation",
        this.formData.IsDeliveryByCustLocation
      );
      payload.append("effectiveStartDate", this.formData.effectiveStartDate);
      payload.append("effectiveEndDate", this.formData.effectiveEndDate);
      payload.append("ExpirationQuantity", this.formData.ExpirationQuantity);
      payload.append(
        "ExpirationQuantityUnitId",
        this.formData.ExpirationQuantityUnitId
      );
      payload.append("Cost", this.formData.Cost);
      payload.append("IsActive", this.formData.IsActive);
      payload.append("AvailableStock", this.formData.AvailableStock);
      payload.append("banner", this.url);
      payload.append("bannerLogo1", this.bannerimage1url);
      payload.append("bannerLogo2", this.bannerimage2url);
      payload.append("bannerLogo3", this.bannerimage3url);
      payload.append("bannerLogo4", this.bannerimage4url);
      payload.append("guid", guid);
      payload.append("timeStamp", timeStamp);

      // for (var pair of payload.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      this.loading = true;
      this.$store
        .dispatch("foodhub/foodhubupdate", payload)
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            this.$router.push("/foodhub-packs");
            Swal.fire("Success!", "Food updated successfully.", "success");
          }
        })
        .catch((err) => {
          this.loading = false;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data
          });
        });
    }
  }
};
</script>

<style scoped>
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
input[type="file"].custom {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}
</style>
